var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.showInput
        ? _c(
            "rtb-card-actions",
            [
              _c("rtb-text-input", {
                attrs: { label: "Search" },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
              _c(
                "rtb-button",
                {
                  attrs: { color: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("clear")
                    },
                  },
                },
                [
                  _c("svg-icon", { attrs: { name: "trash-regular" } }),
                  _vm._v(" Clear "),
                ],
                1
              ),
              _c(
                "rtb-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showInput = true
                    },
                  },
                },
                [
                  _c("svg-icon", { attrs: { name: "plus-regular" } }),
                  _vm._v(" Add "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showInput
        ? _c(
            "rtb-container",
            [
              _c(
                "rtb-card-body",
                [
                  _c("rtb-textarea", {
                    attrs: { value: _vm.model, label: "Match Emails" },
                    on: {
                      change: function ($event) {
                        _vm.model = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "rtb-card-actions",
                [
                  _c(
                    "rtb-button",
                    { on: { click: _vm.onAddEmails } },
                    [
                      _c("svg-icon", { attrs: { name: "save-regular" } }),
                      _vm._v(" Add"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("MatchEmailsTable", {
            attrs: {
              data: _vm.data,
              game: _vm.game,
              teams: _vm.teams,
              search: _vm.search,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }