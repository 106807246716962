var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.localGame
    ? _c(
        "validation-observer",
        { ref: "observer", attrs: { slim: "" } },
        [
          _c(
            "rtb-card",
            { staticClass: "game-settings" },
            [
              _c(
                "rtb-tabs",
                {
                  on: { tabKey: _vm.onTabChange },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "rtb-tab",
                    { key: "general", attrs: { title: "General" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "ID", disabled: "" },
                                    model: {
                                      value: _vm.localGame.id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "id", $$v)
                                      },
                                      expression: "localGame.id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Internal Name",
                                      rules: _vm.getRules().GameName,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("What we call the game"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3632355594
                                    ),
                                    model: {
                                      value: _vm.localGame.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "name", $$v)
                                      },
                                      expression: "localGame.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "External Name",
                                      rules: _vm.getRules().GameExternalName,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "What the participants see"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4222301963
                                    ),
                                    model: {
                                      value: _vm.localGame.externalName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "externalName",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.externalName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("RtbSelect", {
                                    attrs: {
                                      options: _vm.entryPageOptions,
                                      label: "Entry Page",
                                    },
                                    model: {
                                      value: _vm.entryPageComputed,
                                      callback: function ($$v) {
                                        _vm.entryPageComputed = $$v
                                      },
                                      expression: "entryPageComputed",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-datepicker", {
                                    attrs: {
                                      label: "Game Date",
                                      timestamp: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Determines when the date and time players will be sucked into a game from the lobby"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3076470350
                                    ),
                                    model: {
                                      value: _vm.localGame.startTimestamp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "startTimestamp",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.startTimestamp",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-timepicker", {
                                    attrs: {
                                      label: "Game Time",
                                      timestamp: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Determines when the date and time players will be sucked into a game from the lobby"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3076470350
                                    ),
                                    model: {
                                      value: _vm.localGame.startTimestamp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "startTimestamp",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.startTimestamp",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Description",
                                      rules: _vm.getRules().GameDescription,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Shown on the login page"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3434789602
                                    ),
                                    model: {
                                      value: _vm.localGame.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Short Description",
                                      rules: _vm.getRules().GameDescription,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Shown on the game cards"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3029200155
                                    ),
                                    model: {
                                      value: _vm.localGame.shortDescription,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "shortDescription",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.shortDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("HostsProvider", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var hosts = ref.hosts
                                            return [
                                              _c("rtb-select", {
                                                attrs: {
                                                  options: hosts,
                                                  label: "Host",
                                                  "option-text": "fullname",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selected-option",
                                                      fn: function (ref) {
                                                        var item = ref.item
                                                        return [
                                                          item.image
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "w-8 h-8 rounded-full mr-1 object-cover",
                                                                attrs: {
                                                                  src: item.image,
                                                                  alt:
                                                                    item.label +
                                                                    " avatar",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "relative",
                                                              staticStyle: {
                                                                top: "2px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.fullname
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    _vm.localGame.hostUserID
                                                      ? {
                                                          key: "append",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "rtb-input-button",
                                                                {
                                                                  attrs: {
                                                                    title:
                                                                      "Remove",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.localGame.hostUserID =
                                                                          null
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg-icon",
                                                                    {
                                                                      attrs: {
                                                                        name: "times-regular",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                    {
                                                      key: "help",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "rtb-inline-help",
                                                            [
                                                              _vm._v(
                                                                "Host that is assigned to the game"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.localGame.hostUserID,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.localGame,
                                                      "hostUserID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localGame.hostUserID",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      284904143
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Session",
                                      rules: _vm.getRules().GameClient,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Where the Game Came From"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3875991073
                                    ),
                                    model: {
                                      value: _vm.localGame.client,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "client", $$v)
                                      },
                                      expression: "localGame.client",
                                    },
                                  }),
                                ],
                                1
                              ),
                              !!_vm.localGame
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "3" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Session ID",
                                          rules: _vm.getClientIDRule(),
                                          disabled: _vm.adding,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "help",
                                              fn: function () {
                                                return [
                                                  _c("rtb-inline-help", [
                                                    _vm._v(
                                                      "The Client ID associated with this game"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2562518320
                                        ),
                                        model: {
                                          value: _vm.localGame.clientID,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "clientID",
                                              $$v
                                            )
                                          },
                                          expression: "localGame.clientID",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Duration (min.)" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("Duration in Minutes"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      868936355
                                    ),
                                    model: {
                                      value: _vm.normalizedGameDuration,
                                      callback: function ($$v) {
                                        _vm.normalizedGameDuration = _vm._n($$v)
                                      },
                                      expression: "normalizedGameDuration",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Start Time Hint" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("Game Date"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      159981469
                                    ),
                                    model: {
                                      value: _vm.localGame.date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "date", $$v)
                                      },
                                      expression: "localGame.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Pick Team Text" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "The text that appears next the host video in the Pick Teams page"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2490021363
                                    ),
                                    model: {
                                      value: _vm.localGame.pickTeamText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "pickTeamText",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.pickTeamText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Later Comer Video" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v("A video "),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1987251703
                                    ),
                                    model: {
                                      value: _vm.localGame.latecomerVideoCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "latecomerVideoCode",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localGame.latecomerVideoCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Game Is Full Custom Text",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "When the game is full, and someone logs in, they receive this error message"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4138807384
                                    ),
                                    model: {
                                      value: _vm.localGame.gameFullText,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "gameFullText",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.gameFullText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.listTabs,
                                      identity: "value",
                                      optionText: "label",
                                      label: "Game Category",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Where this game will appear, and in what lists, is controlled by this setting "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      752340007
                                    ),
                                    model: {
                                      value: _vm.localGame.runStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "runStatus",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.runStatus",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.accessOptions,
                                      identity: "value",
                                      optionText: "label",
                                      label: "Access",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Private games are visible to you and admins "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2278104672
                                    ),
                                    model: {
                                      value: _vm.accessComputed,
                                      callback: function ($$v) {
                                        _vm.accessComputed = $$v
                                      },
                                      expression: "accessComputed",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Potato",
                                      rules: _vm.getRules().GamePotato,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "After what number of players do we downgrade the video to ensure performance"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      3678326252
                                    ),
                                    model: {
                                      value: _vm.localGame.potato,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "potato", $$v)
                                      },
                                      expression: "localGame.potato",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Max Scribes in Social" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Max Number of Scribes in Social missions"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4020194789
                                    ),
                                    model: {
                                      value: _vm.localGame.maxScribesInSocial,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "maxScribesInSocial",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "localGame.maxScribesInSocial",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: {
                                      label: "Number of Players",
                                      rules: _vm.getRules().NumberOfPlayers,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Maximum number of players allowed in this game room"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      124279853
                                    ),
                                    model: {
                                      value: _vm.localGame.players,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "players", $$v)
                                      },
                                      expression: "localGame.players",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Interim Video" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "A video that plays when getting sucked into a game room"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1527171484
                                    ),
                                    model: {
                                      value: _vm.localGame.interimVideoCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "interimVideoCode",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.interimVideoCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              (_vm.localGame.runStatus == "Masters" ||
                                _vm.localGame.runStatus == "Blocks") &&
                              _vm.isHost
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "3" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: { label: "Version #" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "help",
                                              fn: function () {
                                                return [
                                                  _c("rtb-inline-help", [
                                                    _vm._v(
                                                      "The version number controls the order in which the games are displayed in Portal and elsewhere"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3257128093
                                        ),
                                        model: {
                                          value: _vm.localGame.version,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "version",
                                              $$v
                                            )
                                          },
                                          expression: "localGame.version",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !!_vm.user.super
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "3" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: { label: "Archived" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "help",
                                              fn: function () {
                                                return [
                                                  _c("rtb-inline-help", [
                                                    _vm._v(
                                                      "Timestamp that indicates when the game was moved the archive"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1748569634
                                        ),
                                        model: {
                                          value: _vm.localGame.deletedTimestamp,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "deletedTimestamp",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression:
                                            "localGame.deletedTimestamp",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Scribe Label" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  'Change from "scribe" to something else'
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2518537463
                                    ),
                                    model: {
                                      value: _vm.localGame.scribeLabel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "scribeLabel",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "localGame.scribeLabel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "End Early Video" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(" End Early Video"),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2100067322
                                    ),
                                    model: {
                                      value: _vm.localGame.earlyEndVideo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "earlyEndVideo",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.earlyEndVideo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.localGame && _vm.localGame.contentToolID
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "3" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label: "Content Tool ID",
                                          disabled: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            _vm.localGame.contentToolID
                                              ? {
                                                  key: "append",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "rtb-input-button",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.localGame.contentToolID =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            attrs: {
                                                              name: "times-regular",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.localGame.contentToolID,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "contentToolID",
                                              $$v
                                            )
                                          },
                                          expression: "localGame.contentToolID",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !!_vm.user.super && _vm.copying
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "3" } },
                                    [
                                      _c("rtb-select", {
                                        attrs: {
                                          options: _vm.orgs,
                                          label: "Copy To Organization",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "help",
                                              fn: function () {
                                                return [
                                                  _c("rtb-inline-help", [
                                                    _vm._v(
                                                      "If you want to copy to another organization choose them here"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          361429473
                                        ),
                                        model: {
                                          value: _vm.copyToOrgID,
                                          callback: function ($$v) {
                                            _vm.copyToOrgID = $$v
                                          },
                                          expression: "copyToOrgID",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Locked" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Players won't be able to enter the game in a non-expo game"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1435435555
                                ),
                                model: {
                                  value: _vm.localGame.locked,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "locked", $$v)
                                  },
                                  expression: "localGame.locked",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Deactivate" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Active games are the ones visible to the players. Deactivated games will be automatically turned on when other active games reach capacity."
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2115609130
                                ),
                                model: {
                                  value: _vm.localGame.deactivate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "deactivate", $$v)
                                  },
                                  expression: "localGame.deactivate",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Staged" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Staged games are not visiable and are not used as part of the auto-assgin"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2595299375
                                ),
                                model: {
                                  value: _vm.localGame.ondeck,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "ondeck", $$v)
                                  },
                                  expression: "localGame.ondeck",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Auditable" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Turn this on to enable spectators in the game."
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2868021491
                                ),
                                model: {
                                  value: _vm.localGame.auditable,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "auditable", $$v)
                                  },
                                  expression: "localGame.auditable",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Started" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "This will start the game, and is automatically sent when you head into the game from the pick teams."
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  395846710
                                ),
                                model: {
                                  value: _vm.localGame.started,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "started", $$v)
                                  },
                                  expression: "localGame.started",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Email Login" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Force email login with verification for all players"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3838402132
                                ),
                                model: {
                                  value: _vm.localGame.regularLogin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "regularLogin", $$v)
                                  },
                                  expression: "localGame.regularLogin",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Disable Tips" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "This will prevent the tips animation from showing after the game"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1476841798
                                ),
                                model: {
                                  value: _vm.localGame.tipsDisabled,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "tipsDisabled", $$v)
                                  },
                                  expression: "localGame.tipsDisabled",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Hide Host" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              "Toggle the host hidden settings"
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1482567235
                                ),
                                model: {
                                  value: _vm.localGame.isHostHidden,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "isHostHidden", $$v)
                                  },
                                  expression: "localGame.isHostHidden",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "No Voting in VAS" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(" No Voting in VAS "),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1626734408
                                ),
                                model: {
                                  value: _vm.localGame.noVote,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "noVote", $$v)
                                  },
                                  expression: "localGame.noVote",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Skip latecomer video" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(" Skip latecomer video "),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4084959805
                                ),
                                model: {
                                  value: _vm.localGame.skipLatecomerVideo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localGame,
                                      "skipLatecomerVideo",
                                      $$v
                                    )
                                  },
                                  expression: "localGame.skipLatecomerVideo",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  label: "Allow Moderators to use pro toolbar",
                                },
                                model: {
                                  value: _vm.localShowProModeratorToolbar,
                                  callback: function ($$v) {
                                    _vm.localShowProModeratorToolbar = $$v
                                  },
                                  expression: "localShowProModeratorToolbar",
                                },
                              }),
                              _c("rtb-checkbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Presenter + Mobile" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              ' Marks the event as a "Presenter + Mobile" event '
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1910779036
                                ),
                                model: {
                                  value: _vm.localGame.mobileHybrid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "mobileHybrid", $$v)
                                  },
                                  expression: "localGame.mobileHybrid",
                                },
                              }),
                              _vm._l(
                                _vm.textChatOptions,
                                function (context, index) {
                                  return _c("rtb-checkbox", {
                                    key: index,
                                    staticClass: "mr-2",
                                    attrs: { label: context.label },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(context.label) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.textChatComputed[context.value],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.textChatComputed,
                                          context.value,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "textChatComputed[context.value]",
                                    },
                                  })
                                }
                              ),
                              _c("RtbCheckbox", {
                                staticClass: "mr-2",
                                attrs: {
                                  label: "Hide Team Tag Except Breakouts",
                                },
                                model: {
                                  value: _vm.localGame.hideTeamTags,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "hideTeamTags", $$v)
                                  },
                                  expression: "localGame.hideTeamTags",
                                },
                              }),
                              _c("RtbCheckbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Remove scribe label" },
                                model: {
                                  value: _vm.localGame.removeScribe,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "removeScribe", $$v)
                                  },
                                  expression: "localGame.removeScribe",
                                },
                              }),
                              _c("RtbCheckbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Disable Video" },
                                model: {
                                  value: _vm.localGame.videoDisabled,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localGame,
                                      "videoDisabled",
                                      $$v
                                    )
                                  },
                                  expression: "localGame.videoDisabled",
                                },
                              }),
                              _c("RtbCheckbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Help Bell" },
                                model: {
                                  value: _vm.localGame.helpBell,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.localGame, "helpBell", $$v)
                                  },
                                  expression: "localGame.helpBell",
                                },
                              }),
                              _c("RtbCheckbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Freeze Scribe" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(
                                              " This would allow for an initial scribe assignment, but then NEVER advance the scribe throughout the game "
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1125965399
                                ),
                                model: {
                                  value: _vm.localGame.staticScribeAssignment,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.localGame,
                                      "staticScribeAssignment",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "localGame.staticScribeAssignment",
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "styles", attrs: { title: "Styles" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "2" } },
                                [
                                  _c("TagSelect", {
                                    model: {
                                      value: _vm.themesTag,
                                      callback: function ($$v) {
                                        _vm.themesTag = $$v
                                      },
                                      expression: "themesTag",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("ThemesProvider", {
                                    attrs: { tag: _vm.themesTag },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var themes = ref.themes
                                            return [
                                              _c("ThemeSelect", {
                                                staticClass: "flex-grow",
                                                attrs: { options: themes },
                                                on: {
                                                  edit: _vm.initThemeEdit,
                                                  remove: _vm.deleteTheme,
                                                },
                                                model: {
                                                  value: _vm.localGame.themeID,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.localGame,
                                                      "themeID",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localGame.themeID",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3967063146
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("StylesEditor", {
                            staticClass: "mt-3",
                            attrs: { value: _vm.getStyles(_vm.localGame) },
                            on: { input: _vm.onStylesInput },
                          }),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "rtb-row",
                                [
                                  _c(
                                    "rtb-col",
                                    { attrs: { xs: "3" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: { label: "Custom Logo Style" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "help",
                                              fn: function () {
                                                return [
                                                  _c("rtb-inline-help", [
                                                    _vm._v("Placeholder"),
                                                  ]),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1389206948
                                        ),
                                        model: {
                                          value: _vm.localGame.customLogoStyle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "customLogoStyle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localGame.customLogoStyle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    color: "danger",
                                    id: "reset-styles-button-" + _vm._uid,
                                  },
                                  on: { click: _vm.resetStylesToDefault },
                                },
                                [_vm._v("RESET")]
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "reset-styles-button-" + _vm._uid,
                                  },
                                },
                                [
                                  _vm._v(
                                    " Remove any game styles and enable inheritance "
                                  ),
                                ]
                              ),
                              _c(
                                "rtb-button",
                                {
                                  staticClass: "mt-3 ml-3",
                                  attrs: {
                                    color: "danger",
                                    id: "set-default-styles-button-" + _vm._uid,
                                  },
                                  on: { click: _vm.setStylesToDefault },
                                },
                                [_vm._v("OVERRIDE")]
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target:
                                      "set-default-styles-button-" + _vm._uid,
                                  },
                                },
                                [
                                  _vm._v(
                                    " Set styles to default values and disable inheritance "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.currentTheme
                        ? _c("ThemeCustomizerModal", {
                            attrs: { theme: _vm.currentTheme },
                            model: {
                              value: _vm.themeCustomizerModalVisible,
                              callback: function ($$v) {
                                _vm.themeCustomizerModalVisible = $$v
                              },
                              expression: "themeCustomizerModalVisible",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "expo", attrs: { title: "Expo" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.gameTypes,
                                      label: "Game Type",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Determines how the game appears and functions in the Game Rooms tab"
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1939286699
                                    ),
                                    model: {
                                      value: _vm.localGame.gameType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "gameType", $$v)
                                      },
                                      expression: "localGame.gameType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.localGame.gameType == "YouTube"
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: {
                                          label:
                                            "The YouTube CODE -- not the Url",
                                        },
                                        model: {
                                          value: _vm.localGame.youTubeUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "youTubeUrl",
                                              $$v
                                            )
                                          },
                                          expression: "localGame.youTubeUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.localGame.gameType == "Our Video"
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: { label: "Video URL" },
                                        model: {
                                          value: _vm.localGame.ourVideoUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "ourVideoUrl",
                                              $$v
                                            )
                                          },
                                          expression: "localGame.ourVideoUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.localGame.gameType == "Twitch"
                                ? _c(
                                    "rtb-col",
                                    { attrs: { xs: "4" } },
                                    [
                                      _c("rtb-text-input", {
                                        attrs: { label: "Twitch Channel" },
                                        model: {
                                          value: _vm.localGame.twitchChannel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "twitchChannel",
                                              $$v
                                            )
                                          },
                                          expression: "localGame.twitchChannel",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "2" } },
                                [
                                  _c("rtb-text-input", {
                                    attrs: { label: "Round #" },
                                    model: {
                                      value: _vm.localGame.round,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "round", $$v)
                                      },
                                      expression: "localGame.round",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.localGame.gameType == "YouTube"
                                ? _c(
                                    "rtb-col",
                                    { staticClass: "mt-3", attrs: { xs: "2" } },
                                    [
                                      _c("rtb-checkbox", {
                                        attrs: { label: "Auto Play" },
                                        model: {
                                          value: _vm.localGame.youTubeAutoPlay,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.localGame,
                                              "youTubeAutoPlay",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localGame.youTubeAutoPlay",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.localGame.gameType == "YouTube" ||
                          _vm.localGame.gameType == "Our Video"
                            ? _c(
                                "rtb-row",
                                { staticClass: "px-2 py-2" },
                                [
                                  _c("rtb-checkbox", {
                                    staticClass: "mr-2",
                                    attrs: { label: "Watch party" },
                                    model: {
                                      value: _vm.localGame.watchParty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "watchParty",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.watchParty",
                                    },
                                  }),
                                  _c("rtb-checkbox", {
                                    staticClass: "mr-2",
                                    attrs: { label: "Loop video" },
                                    model: {
                                      value: _vm.localGame.videoLoop,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "videoLoop",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.videoLoop",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "images", attrs: { title: "Images" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Card Image" },
                                    model: {
                                      value: _vm.localGame.image,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.localGame, "image", $$v)
                                      },
                                      expression: "localGame.image",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Custom Logo" },
                                    model: {
                                      value: _vm.localGame.customLogo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "customLogo",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.customLogo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Custom Logo Large" },
                                    model: {
                                      value: _vm.localGame.customLogoLarge,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "customLogoLarge",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.customLogoLarge",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Pick Team Logo" },
                                    model: {
                                      value: _vm.localGame.pickTeamLogo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "pickTeamLogo",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.pickTeamLogo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "3" } },
                                [
                                  _c("rtb-select", {
                                    attrs: {
                                      options: _vm.gameCardImages,
                                      identity: "value",
                                      optionText: "label",
                                      label: "Game Card Images",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "help",
                                          fn: function () {
                                            return [
                                              _c("rtb-inline-help", [
                                                _vm._v(
                                                  "Where this game will appear, and in what lists, is controlled by this setting "
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      752340007
                                    ),
                                    model: {
                                      value: _vm.localGame.gameCardImage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "gameCardImage",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.gameCardImage",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "images", attrs: { title: "Backgrounds" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center",
                                    "font-size": "18px",
                                    color: "#fff",
                                  },
                                  attrs: { xs: "2" },
                                },
                                [
                                  _vm._v(
                                    " Opacity " +
                                      _vm._s(_vm.gameBackgroundOpacity) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "10" } },
                                [
                                  _c("v-slider", {
                                    attrs: { max: 100, step: 1 },
                                    model: {
                                      value: _vm.gameBackgroundOpacity,
                                      callback: function ($$v) {
                                        _vm.gameBackgroundOpacity = $$v
                                      },
                                      expression: "gameBackgroundOpacity",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "rtb-row",
                            [
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Game Background" },
                                    model: {
                                      value: _vm.localGame.backgroundImage,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "backgroundImage",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.backgroundImage",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Shift A" },
                                    model: {
                                      value: _vm.localGame.bgTriggerA,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "bgTriggerA",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.bgTriggerA",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Shift S" },
                                    model: {
                                      value: _vm.localGame.bgTriggerS,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "bgTriggerS",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.bgTriggerS",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Shift D" },
                                    model: {
                                      value: _vm.localGame.bgTriggerD,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "bgTriggerD",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.bgTriggerD",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "rtb-col",
                                { attrs: { xs: "4" } },
                                [
                                  _c("image-uploader-next", {
                                    attrs: { label: "Shift F" },
                                    model: {
                                      value: _vm.localGame.bgTriggerF,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.localGame,
                                          "bgTriggerF",
                                          $$v
                                        )
                                      },
                                      expression: "localGame.bgTriggerF",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "match-emails", attrs: { title: "Emails Match" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _c("GameSettingsMatchEmails", {
                            attrs: {
                              game: _vm.localGame,
                              orgID: _vm.orgID,
                              data: _vm.mailMatchingData,
                              teams: _vm.teams,
                            },
                            on: {
                              clear: _vm.onMatchingClear,
                              add: _vm.onMatchingAdd,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "locked", attrs: { title: "Escape Rooms" } },
                    [
                      _c("AutomationPanel", {
                        model: {
                          value: _vm.localGame,
                          callback: function ($$v) {
                            _vm.localGame = $$v
                          },
                          expression: "localGame",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "HybridTeams", attrs: { title: "Hybrid Teams" } },
                    [
                      _c(
                        "rtb-card-body",
                        [
                          _vm.canLoadClientTeam
                            ? _c("GameTeams", {
                                attrs: {
                                  game: _vm.gameTeams,
                                  playthroughs: _vm.playthroughs,
                                  scroll: true,
                                },
                                on: { updateLocalGame: _vm.updateLocalGame },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "rtb-tab",
                    { key: "experimental", attrs: { title: "Experimental" } },
                    [
                      _c("ExperimentalPanel", {
                        model: {
                          value: _vm.localGame,
                          callback: function ($$v) {
                            _vm.localGame = $$v
                          },
                          expression: "localGame",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "rtb-card-actions",
                [
                  _vm.isTemplateGame
                    ? _c("v-switch", {
                        attrs: { color: "error", label: "Locked" },
                        model: {
                          value: _vm.localGame.frozen,
                          callback: function ($$v) {
                            _vm.$set(_vm.localGame, "frozen", $$v)
                          },
                          expression: "localGame.frozen",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "time",
                    {
                      staticClass: "mr-auto",
                      attrs: { datetime: _vm.endDate },
                    },
                    [_vm._v(_vm._s(_vm.endDate))]
                  ),
                  _c("rtb-spinner", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                  }),
                  _vm.adding === false
                    ? _c(
                        "RtbButton",
                        {
                          attrs: { color: "grey", disabled: _vm.exporting },
                          on: { click: _vm.exportAsRoom },
                        },
                        [_vm._v("Export (AS ROOM)")]
                      )
                    : _vm._e(),
                  _vm.adding === false
                    ? _c(
                        "RtbButton",
                        {
                          attrs: { color: "grey", disabled: _vm.exporting },
                          on: { click: _vm.exportAsActivityGroup },
                        },
                        [_vm._v("Export (AS BLOCK)")]
                      )
                    : _vm._e(),
                  !!_vm.game
                    ? _c(
                        "rtb-button",
                        {
                          attrs: { disabled: _vm.loading, color: "secondary" },
                          on: { click: _vm.claim },
                        },
                        [
                          !!_vm.owner
                            ? [
                                _c("div", [
                                  _vm.owner.image
                                    ? _c("img", {
                                        staticClass:
                                          "w-8 h-8 rounded-full mr-1 object-cover",
                                        attrs: {
                                          src: _vm.owner.image,
                                          alt: _vm.owner.firstname + " avatar",
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "5px" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.owner.firstname +
                                            " " +
                                            _vm.owner.lastname
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" Claim "),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "rtb-button",
                    {
                      attrs: { color: "dark", disabled: _vm.loading },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _vm.copying
                    ? _c(
                        "rtb-button",
                        {
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.update(true)
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { name: "copy-regular" } }),
                          _vm._v(" Copy"),
                        ],
                        1
                      )
                    : _vm.adding
                    ? _c(
                        "rtb-button",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.update(true)
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { name: "plus-regular" } }),
                          _vm._v(" Create"),
                        ],
                        1
                      )
                    : _c(
                        "rtb-button",
                        {
                          attrs: {
                            disabled: _vm.loading || _vm.localGame.frozen,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.update()
                            },
                          },
                        },
                        [
                          _c("svg-icon", { attrs: { name: "save-regular" } }),
                          _vm._v(" Save "),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }