







































import Vue from "vue"

import {
  RtbTextarea,
  RtbTextInput,
  RtbButton,
  RtbCardActions,
  RtbCardBody,
  RtbContainer
} from "@/components/ui"
import MatchEmailsTable from "@/components/Game/MatchEmailsTable.vue"

export default Vue.extend({
  name: "GameSettingsMatchEmails",
  components: {
    RtbTextarea,
    RtbTextInput,
    RtbButton,
    RtbCardActions,
    MatchEmailsTable,
    RtbCardBody,
    RtbContainer
  },
  props: {
    game: {
      type: Object
    },
    data: {
      type: Array
    },
    teams: {
      type: Object
    }
  },
  data() {
    return {
      emailList: [],
      showInput: false,
      search: ""
    }
  },
  methods: {
    onAddEmails() {
      const data = []
      this.emailList.forEach(email => {
        if (!email) return
        data.push({ email })
      })
      this.$emit("add", data)
      this.showInput = false
    }
  },
  computed: {
    model: {
      get(): string {
        return this.emailList?.join(", ") || ""
      },
      set(value: string) {
        const result = value
          .split(/[\s,;]+/)
          .filter(item => item)
          .map(item => item.toLowerCase())
        this.emailList = result
      }
    }
  }
})
