var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card-body",
    [
      _c(
        "RtbRow",
        [
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbCheckbox", {
                attrs: { label: "Locked Room" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v(
                            "This locks the room until it is unlocked by a successfully solved unlock mission"
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.escapeRoom,
                  callback: function ($$v) {
                    _vm.escapeRoom = $$v
                  },
                  expression: "escapeRoom",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbCheckbox", {
                attrs: { label: "Allow Re-Entry Until Unlocked" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v(
                            "Without this, the room will be considered played right when the player enters the game. This waits till the unlock moment and then consideres it played "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.allowReentry,
                  callback: function ($$v) {
                    _vm.allowReentry = $$v
                  },
                  expression: "allowReentry",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbCheckbox", {
                attrs: { label: "Show Answers" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v(
                            "This shows the answer in the text field so that you can test the game"
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.showAnswers,
                  callback: function ($$v) {
                    _vm.showAnswers = $$v
                  },
                  expression: "showAnswers",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbCheckbox", {
                attrs: { label: "Do It Yourself" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v(
                            "DIY is the designation for games that are run without a host. This includes DIY Activity Rooms as well as DIY Experiences. "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.diy,
                  callback: function ($$v) {
                    _vm.diy = $$v
                  },
                  expression: "diy",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _vm.diy || _vm.moderated
                ? _c("RtbCheckbox", {
                    attrs: { label: "AutoWeve" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "help",
                          fn: function () {
                            return [
                              _c("RtbInlineHelp", [
                                _vm._v(
                                  "On the player toolbar, an icon appears that will let any player become the most of the game."
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2868171305
                    ),
                    model: {
                      value: _vm.moderated,
                      callback: function ($$v) {
                        _vm.moderated = $$v
                      },
                      expression: "moderated",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbCheckbox", {
                attrs: { label: "Auto Enable Autopilot" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v(
                            "Start auto-advancing when someone enters the game"
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.hostless2Computed,
                  callback: function ($$v) {
                    _vm.hostless2Computed = $$v
                  },
                  expression: "hostless2Computed",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _vm.moderated && _vm.diy
                ? _c("RtbCheckbox", {
                    attrs: { label: "Turn off activity sets" },
                    model: {
                      value: _vm.offActivitySets,
                      callback: function ($$v) {
                        _vm.offActivitySets = $$v
                      },
                      expression: "offActivitySets",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.hostless2Computed || (_vm.value && _vm.value.autopilot)
        ? _c(
            "RtbRow",
            { staticClass: "mt-3" },
            [
              _c(
                "RtbCol",
                { attrs: { xs: "4" } },
                [
                  _c("RtbCheckbox", {
                    attrs: { label: "Auto Copy" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "help",
                          fn: function () {
                            return [
                              _c("RtbInlineHelp", [
                                _vm._v(
                                  " Automatically end the game and create a copy "
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1825340817
                    ),
                    model: {
                      value: _vm.onCompleteCopy,
                      callback: function ($$v) {
                        _vm.onCompleteCopy = $$v
                      },
                      expression: "onCompleteCopy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "RtbCol",
                { attrs: { xs: "4" } },
                [
                  _c("RtbCheckbox", {
                    attrs: { label: "Auto End" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "help",
                          fn: function () {
                            return [
                              _c("RtbInlineHelp", [
                                _vm._v(" Automatically end the game "),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1472599130
                    ),
                    model: {
                      value: _vm.onCompleteEnd,
                      callback: function ($$v) {
                        _vm.onCompleteEnd = $$v
                      },
                      expression: "onCompleteEnd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "RtbRow",
        { staticClass: "mt-3" },
        [
          _c(
            "RtbCol",
            { attrs: { xs: "3" } },
            [
              _c("rtb-select", {
                attrs: { options: _vm.trwOptions, label: "TRW Options" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v("Number of options for TRW shown."),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.trwOptionsComputed,
                  callback: function ($$v) {
                    _vm.trwOptionsComputed = $$v
                  },
                  expression: "trwOptionsComputed",
                },
              }),
            ],
            1
          ),
          _c(
            "RtbCol",
            { attrs: { xs: "4" } },
            [
              _c("RtbCheckbox", {
                staticClass: "ml-2",
                staticStyle: { "margin-top": "30px" },
                attrs: { label: "Randomize" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("RtbInlineHelp", [
                          _vm._v(
                            " Will randomly pull from the available blocks "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.trwRandomize,
                  callback: function ($$v) {
                    _vm.trwRandomize = $$v
                  },
                  expression: "trwRandomize",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      !!_vm.value
        ? _c(
            "RtbRow",
            { staticClass: "mt-3" },
            [
              _c(
                "RtbCol",
                { staticClass: "white--text", attrs: { xs: "12" } },
                [
                  _c("table", [
                    _c("tr", [
                      _c("td", [_vm._v("Game ID:")]),
                      _c("td", [_vm._v(_vm._s(_vm.value.theKey))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Original Game ID:")]),
                      _c("td", [_vm._v(_vm._s(_vm.value.originalGameID))]),
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Copied Game ID:")]),
                      _c("td", [_vm._v(_vm._s(_vm.value.copiedFrom))]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.value && _vm.value.autopilot
        ? _c(
            "RtbRow",
            { staticClass: "mt-3" },
            [
              _c(
                "RtbCol",
                { staticClass: "white--text", attrs: { xs: "12" } },
                [
                  _vm.value
                    ? _c("div", [
                        _vm._v(" Info: "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              color: "#19bf2a",
                            },
                          },
                          [_vm._v("autopilot is on")]
                        ),
                        _vm._v(" (turn off hostless to disable) "),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }