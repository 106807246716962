<template>
  <rtb-card-body>
    <RtbRow>
      <RtbCol xs="4">
        <RtbCheckbox v-model="escapeRoom" label="Locked Room">
          <template #help
            ><RtbInlineHelp
              >This locks the room until it is unlocked by a successfully solved
              unlock mission</RtbInlineHelp
            ></template
          >
        </RtbCheckbox>
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox
          v-model="allowReentry"
          label="Allow Re-Entry Until Unlocked"
        >
          <template #help
            ><RtbInlineHelp
              >Without this, the room will be considered played right when the
              player enters the game. This waits till the unlock moment and then
              consideres it played
            </RtbInlineHelp></template
          >
        </RtbCheckbox>
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox v-model="showAnswers" label="Show Answers">
          <template #help
            ><RtbInlineHelp
              >This shows the answer in the text field so that you can test the
              game</RtbInlineHelp
            ></template
          >
        </RtbCheckbox>
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox v-model="diy" label="Do It Yourself">
          <template #help
            ><RtbInlineHelp
              >DIY is the designation for games that are run without a host.
              This includes DIY Activity Rooms as well as DIY Experiences.
            </RtbInlineHelp></template
          >
        </RtbCheckbox>
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox
          v-if="diy || moderated"
          v-model="moderated"
          label="AutoWeve"
          ><template #help
            ><RtbInlineHelp
              >On the player toolbar, an icon appears that will let any player
              become the most of the game.</RtbInlineHelp
            ></template
          >
        </RtbCheckbox>
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox v-model="hostless2Computed" label="Auto Enable Autopilot"
          ><template #help
            ><RtbInlineHelp
              >Start auto-advancing when someone enters the game</RtbInlineHelp
            ></template
          ></RtbCheckbox
        >
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox
          v-if="moderated && diy"
          v-model="offActivitySets"
          label="Turn off activity sets"
        />
      </RtbCol>
    </RtbRow>
    <RtbRow v-if="hostless2Computed || (value && value.autopilot)" class="mt-3">
      <RtbCol xs="4">
        <RtbCheckbox v-model="onCompleteCopy" label="Auto Copy">
          <template #help>
            <RtbInlineHelp>
              Automatically end the game and create a copy
            </RtbInlineHelp>
          </template>
        </RtbCheckbox>
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox v-model="onCompleteEnd" label="Auto End">
          <template #help>
            <RtbInlineHelp> Automatically end the game </RtbInlineHelp>
          </template>
        </RtbCheckbox>
      </RtbCol>
    </RtbRow>
    <RtbRow class="mt-3">
      <RtbCol xs="3">
        <rtb-select
          v-model="trwOptionsComputed"
          :options="trwOptions"
          label="TRW Options"
        >
          <template #help>
            <RtbInlineHelp
              >Number of options for TRW shown.</RtbInlineHelp
            ></template
          ></rtb-select
        >
      </RtbCol>
      <RtbCol xs="4">
        <RtbCheckbox
          v-model="trwRandomize"
          label="Randomize"
          class="ml-2"
          style="margin-top: 30px"
        >
          <template #help>
            <RtbInlineHelp>
              Will randomly pull from the available blocks
            </RtbInlineHelp>
          </template>
        </RtbCheckbox>
      </RtbCol>
    </RtbRow>
    <RtbRow class="mt-3" v-if="!!value">
      <RtbCol class="white--text" xs="12">
        <table>
          <tr>
            <td>Game ID:</td>
            <td>{{ value.theKey }}</td>
          </tr>
          <tr>
            <td>Original Game ID:</td>
            <td>{{ value.originalGameID }}</td>
          </tr>
          <tr>
            <td>Copied Game ID:</td>
            <td>{{ value.copiedFrom }}</td>
          </tr>
        </table>
      </RtbCol>
    </RtbRow>
    <RtbRow class="mt-3" v-if="value && value.autopilot">
      <RtbCol class="white--text" xs="12">
        <div v-if="value">
          Info:
          <span style="font-weight: bold; color: #19bf2a">autopilot is on</span>
          (turn off hostless to disable)
        </div>
      </RtbCol>
    </RtbRow>
  </rtb-card-body>
</template>

<script>
import Vue from "vue"
import {
  RtbRow,
  RtbCol,
  RtbCardBody,
  RtbCheckbox,
  RtbInlineHelp,
  RtbSelect
} from "@/components/ui"

export default Vue.extend({
  name: "AutomationPanel",
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    RtbRow,
    RtbCardBody,
    RtbCol,
    RtbCheckbox,
    RtbInlineHelp,
    RtbSelect
  },
  props: {
    value: Object
  },
  computed: {
    escapeRoom: {
      get() {
        return Boolean(this.value?.escapeRoom)
      },
      set(value) {
        this.$emit("change", { ...this.value, escapeRoom: Boolean(value) })
      }
    },
    allowReentry: {
      get() {
        return Boolean(this.value?.allowReentry)
      },
      set(value) {
        this.$emit("change", { ...this.value, allowReentry: Boolean(value) })
      }
    },
    showAnswers: {
      get() {
        return Boolean(this.value?.showAnswers)
      },
      set(value) {
        this.$emit("change", { ...this.value, showAnswers: Boolean(value) })
      }
    },
    diy: {
      get() {
        return Boolean(this.value?.diy)
      },
      set(value) {
        this.$emit("change", { ...this.value, diy: Boolean(value) })
      }
    },
    moderated: {
      get() {
        return Boolean(this.value?.moderated)
      },
      set(value) {
        this.$emit("change", { ...this.value, moderated: Boolean(value) })
      }
    },
    offActivitySets: {
      get() {
        return Boolean(this.value?.offActivitySets)
      },
      set(value) {
        this.$emit("change", { ...this.value, offActivitySets: Boolean(value) })
      }
    },
    trwRandomize: {
      get() {
        return Boolean(this.value?.trwRandomize)
      },
      set(value) {
        this.$emit("change", { ...this.value, trwRandomize: Boolean(value) })
      }
    },
    trwOptionsComputed: {
      get() {
        return this.value?.trwOptions
      },
      set(value) {
        this.$emit("change", { ...this.value, trwOptions: value })
      }
    },
    onCompleteEnd: {
      get() {
        return Boolean(this.value?.onComplete?.end || this.onCompleteCopy)
      },
      set(value) {
        const game = { ...this.value }
        if (value) {
          game.onComplete = { end: true }
        } else {
          game.onComplete = { end: false }
        }
        this.$emit("change", game)
      }
    },
    onCompleteCopy: {
      get() {
        return (
          !this.value?.onComplete ||
          (this.value?.onComplete?.copy && this.value?.onComplete?.end)
        )
      },
      set(value) {
        const game = { ...this.value }
        if (value) {
          game.onComplete = { copy: true, end: true }
        } else {
          game.onComplete = { copy: false, end: false }
        }
        this.$emit("change", game)
      }
    },
    hostless2Computed: {
      get() {
        return Boolean(this.value?.hostless2)
      },
      set(value) {
        const game = { ...this.value }
        if (value) {
          game.hostless2 = true
        } else {
          game.hostless2 = false
          game.autopilot = false
        }
        this.$emit("change", game)
      }
    },
    trwOptions() {
      return [2, 3, 4, 5, 6, 7, 8, 9]
    }
  }
})
</script>
