var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card-body",
    [
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "4" } },
            [
              _c("rtb-text-input", {
                attrs: { label: "Content Tool Template Version" },
                model: {
                  value: _vm.value.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "version", $$v)
                  },
                  expression: "value.version",
                },
              }),
            ],
            1
          ),
          _c(
            "rtb-col",
            { attrs: { xs: "4" } },
            [
              _c("rtb-text-input", {
                attrs: { disabled: "", label: "Content Tool Template ID" },
                model: {
                  value: _vm.value.contentToolID,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "contentToolID", $$v)
                  },
                  expression: "value.contentToolID",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "rtb-row",
        [
          _c(
            "rtb-col",
            { attrs: { xs: "4" } },
            [
              _c("rtb-checkbox", {
                staticClass: "mr-2",
                attrs: { label: "Next Gen Navigation" },
                scopedSlots: _vm._u([
                  {
                    key: "help",
                    fn: function () {
                      return [
                        _c("rtb-inline-help", [_vm._v(" Next Gen Navigation")]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.value.experimental,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "experimental", $$v)
                  },
                  expression: "value.experimental",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }