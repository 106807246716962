<template>
  <rtb-card-body>
    <rtb-row>
      <rtb-col xs="4">
        <rtb-text-input
          v-model="value.version"
          label="Content Tool Template Version"
        />
      </rtb-col>
      <rtb-col xs="4">
        <rtb-text-input
          disabled
          v-model="value.contentToolID"
          label="Content Tool Template ID"
        />
      </rtb-col>
    </rtb-row>
    <rtb-row>
      <rtb-col xs="4">
        <rtb-checkbox
          v-model="value.experimental"
          label="Next Gen Navigation"
          class="mr-2"
        >
          <template #help>
            <rtb-inline-help> Next Gen Navigation</rtb-inline-help>
          </template>
        </rtb-checkbox>
      </rtb-col>
    </rtb-row>
  </rtb-card-body>
</template>

<script>
import Vue from "vue"
import {
  RtbRow,
  RtbCol,
  RtbCardBody,
  RtbCheckbox,
  RtbInlineHelp,
  RtbTextInput,
  RtbButton
} from "@/components/ui"

export default Vue.extend({
  name: "ExperimentalPanel",
  model: {
    prop: "value",
    event: "change"
  },
  components: {
    RtbTextInput,
    RtbRow,
    RtbCardBody,
    RtbCol,
    RtbCheckbox,
    RtbInlineHelp,
    RtbButton
  },
  props: {
    value: Object
  }
})
</script>
