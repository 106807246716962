<template>
  <!-- TODO -->
  <!-- Move all this mess to panel components -->
  <validation-observer v-if="localGame" ref="observer" slim>
    <rtb-card class="game-settings">
      <rtb-tabs v-model="tab" @tabKey="onTabChange">
        <rtb-tab key="general" title="General">
          <rtb-card-body>
            <rtb-row>
              <rtb-col xs="3">
                <rtb-text-input v-model="localGame.id" label="ID" disabled />
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.name"
                  label="Internal Name"
                  :rules="getRules().GameName"
                  ><template #help>
                    <rtb-inline-help
                      >What we call the game</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.externalName"
                  label="External Name"
                  :rules="getRules().GameExternalName"
                  ><template #help>
                    <rtb-inline-help
                      >What the participants see</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <RtbSelect
                  v-model="entryPageComputed"
                  :options="entryPageOptions"
                  label="Entry Page"
                />
              </rtb-col>
              <rtb-col xs="3">
                <rtb-datepicker
                  v-model="localGame.startTimestamp"
                  label="Game Date"
                  timestamp
                >
                  <template #help>
                    <rtb-inline-help
                      >Determines when the date and time players will be sucked
                      into a game from the lobby</rtb-inline-help
                    ></template
                  >
                </rtb-datepicker>
              </rtb-col>
              <rtb-col xs="3">
                <rtb-timepicker
                  v-model="localGame.startTimestamp"
                  label="Game Time"
                  timestamp
                >
                  <template #help>
                    <rtb-inline-help
                      >Determines when the date and time players will be sucked
                      into a game from the lobby</rtb-inline-help
                    ></template
                  >
                </rtb-timepicker>
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.description"
                  label="Description"
                  :rules="getRules().GameDescription"
                  ><template #help>
                    <rtb-inline-help
                      >Shown on the login page</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.shortDescription"
                  label="Short Description"
                  :rules="getRules().GameDescription"
                  ><template #help>
                    <rtb-inline-help
                      >Shown on the game cards</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>

              <rtb-col xs="3">
                <HostsProvider #default="{ hosts }">
                  <rtb-select
                    v-model="localGame.hostUserID"
                    :options="hosts"
                    label="Host"
                    option-text="fullname"
                  >
                    <template #selected-option="{ item }">
                      <img
                        v-if="item.image"
                        :src="item.image"
                        :alt="`${item.label} avatar`"
                        class="w-8 h-8 rounded-full mr-1 object-cover"
                      />
                      <span class="relative" style="top: 2px">{{
                        item.fullname
                      }}</span>
                    </template>
                    <template v-if="localGame.hostUserID" #append>
                      <rtb-input-button
                        title="Remove"
                        @click="localGame.hostUserID = null"
                        ><svg-icon name="times-regular"
                      /></rtb-input-button>
                    </template>
                    <template #help>
                      <rtb-inline-help
                        >Host that is assigned to the game</rtb-inline-help
                      ></template
                    ></rtb-select
                  >
                </HostsProvider>
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.client"
                  label="Session"
                  :rules="getRules().GameClient"
                  ><template #help>
                    <rtb-inline-help
                      >Where the Game Came From</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3" v-if="!!localGame">
                <rtb-text-input
                  v-model="localGame.clientID"
                  label="Session ID"
                  :rules="getClientIDRule()"
                  :disabled="adding"
                  ><template #help>
                    <rtb-inline-help
                      >The Client ID associated with this game</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model.number="normalizedGameDuration"
                  label="Duration (min.)"
                  ><template #help>
                    <rtb-inline-help
                      >Duration in Minutes</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.date"
                  label="Start Time Hint"
                >
                  <template #help>
                    <rtb-inline-help>Game Date</rtb-inline-help></template
                  >
                </rtb-text-input>
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.pickTeamText"
                  label="Pick Team Text"
                  ><template #help>
                    <rtb-inline-help
                      >The text that appears next the host video in the Pick
                      Teams page</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.latecomerVideoCode"
                  label="Later Comer Video"
                  ><template #help>
                    <rtb-inline-help>A video </rtb-inline-help></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.gameFullText"
                  label="Game Is Full Custom Text"
                  ><template #help>
                    <rtb-inline-help
                      >When the game is full, and someone logs in, they receive
                      this error message</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-select
                  v-model="localGame.runStatus"
                  :options="listTabs"
                  identity="value"
                  optionText="label"
                  label="Game Category"
                  ><template #help>
                    <rtb-inline-help
                      >Where this game will appear, and in what lists, is
                      controlled by this setting
                    </rtb-inline-help></template
                  ></rtb-select
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-select
                  v-model="accessComputed"
                  :options="accessOptions"
                  identity="value"
                  optionText="label"
                  label="Access"
                  ><template #help>
                    <rtb-inline-help
                      >Private games are visible to you and admins
                    </rtb-inline-help></template
                  ></rtb-select
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.potato"
                  label="Potato"
                  :rules="getRules().GamePotato"
                  ><template #help>
                    <rtb-inline-help
                      >After what number of players do we downgrade the video to
                      ensure performance</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.maxScribesInSocial"
                  label="Max Scribes in Social"
                  ><template #help>
                    <rtb-inline-help
                      >Max Number of Scribes in Social missions</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.players"
                  label="Number of Players"
                  :rules="getRules().NumberOfPlayers"
                  ><template #help>
                    <rtb-inline-help
                      >Maximum number of players allowed in this game
                      room</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>

              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.interimVideoCode"
                  label="Interim Video"
                  ><template #help>
                    <rtb-inline-help
                      >A video that plays when getting sucked into a game
                      room</rtb-inline-help
                    ></template
                  ></rtb-text-input
                >
              </rtb-col>
              <rtb-col
                v-if="
                  (localGame.runStatus == 'Masters' ||
                    localGame.runStatus == 'Blocks') &&
                  isHost
                "
                xs="3"
              >
                <rtb-text-input v-model="localGame.version" label="Version #">
                  <template #help>
                    <rtb-inline-help
                      >The version number controls the order in which the games
                      are displayed in Portal and elsewhere</rtb-inline-help
                    ></template
                  >
                </rtb-text-input>
              </rtb-col>
              <rtb-col v-if="!!user.super" xs="3">
                <rtb-text-input
                  v-model.number="localGame.deletedTimestamp"
                  label="Archived"
                >
                  <template #help>
                    <rtb-inline-help
                      >Timestamp that indicates when the game was moved the
                      archive</rtb-inline-help
                    ></template
                  >
                </rtb-text-input>
              </rtb-col>
              <rtb-col xs="3">
                <rtb-text-input
                  v-model.number="localGame.scribeLabel"
                  label="Scribe Label"
                >
                  <template #help>
                    <rtb-inline-help
                      >Change from "scribe" to something else</rtb-inline-help
                    ></template
                  >
                </rtb-text-input>
              </rtb-col>

              <rtb-col xs="3">
                <rtb-text-input
                  v-model="localGame.earlyEndVideo"
                  label="End Early Video"
                >
                  <template #help>
                    <rtb-inline-help> End Early Video</rtb-inline-help>
                  </template>
                </rtb-text-input>
              </rtb-col>

              <rtb-col xs="3" v-if="localGame && localGame.contentToolID">
                <rtb-text-input
                  v-model="localGame.contentToolID"
                  label="Content Tool ID"
                  disabled
                >
                  <template v-if="localGame.contentToolID" #append>
                    <rtb-input-button @click="localGame.contentToolID = null">
                      <svg-icon name="times-regular" />
                    </rtb-input-button>
                  </template>
                </rtb-text-input>
              </rtb-col>

              <rtb-col v-if="!!user.super && copying" xs="3">
                <rtb-select
                  v-model="copyToOrgID"
                  :options="orgs"
                  label="Copy To Organization"
                  ><template #help>
                    <rtb-inline-help
                      >If you want to copy to another organization choose them
                      here</rtb-inline-help
                    ></template
                  ></rtb-select
                >
              </rtb-col>
            </rtb-row>
            <div class="mt-3">
              <rtb-checkbox
                v-model="localGame.locked"
                label="Locked"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >Players won't be able to enter the game in a non-expo
                    game</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >
              <rtb-checkbox
                v-model="localGame.deactivate"
                label="Deactivate"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >Active games are the ones visible to the players.
                    Deactivated games will be automatically turned on when other
                    active games reach capacity.</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >
              <rtb-checkbox
                v-model="localGame.ondeck"
                label="Staged"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >Staged games are not visiable and are not used as part of
                    the auto-assgin</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >

              <rtb-checkbox
                v-model="localGame.auditable"
                label="Auditable"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >Turn this on to enable spectators in the
                    game.</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >
              <rtb-checkbox
                v-model="localGame.started"
                label="Started"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >This will start the game, and is automatically sent when
                    you head into the game from the pick teams.</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >
              <rtb-checkbox
                v-model="localGame.regularLogin"
                label="Email Login"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >Force email login with verification for all
                    players</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >
              <rtb-checkbox
                v-model="localGame.tipsDisabled"
                label="Disable Tips"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >This will prevent the tips animation from showing after the
                    game</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >

              <rtb-checkbox
                v-model="localGame.isHostHidden"
                label="Hide Host"
                class="mr-2"
                ><template #help
                  ><rtb-inline-help
                    >Toggle the host hidden settings</rtb-inline-help
                  ></template
                ></rtb-checkbox
              >
              <rtb-checkbox
                v-model="localGame.noVote"
                label="No Voting in VAS"
                class="mr-2"
              >
                <template #help>
                  <rtb-inline-help> No Voting in VAS </rtb-inline-help>
                </template>
              </rtb-checkbox>
              <rtb-checkbox
                v-model="localGame.skipLatecomerVideo"
                label="Skip latecomer video"
                class="mr-2"
              >
                <template #help>
                  <rtb-inline-help> Skip latecomer video </rtb-inline-help>
                </template>
              </rtb-checkbox>
              <rtb-checkbox
                v-model="localShowProModeratorToolbar"
                label="Allow Moderators to use pro toolbar"
                class="mr-2"
              />
              <rtb-checkbox
                v-model="localGame.mobileHybrid"
                label="Presenter + Mobile"
                class="mr-2"
              >
                <template #help>
                  <rtb-inline-help>
                    Marks the event as a "Presenter + Mobile" event
                  </rtb-inline-help>
                </template>
              </rtb-checkbox>

              <rtb-checkbox
                v-for="(context, index) in textChatOptions"
                :key="index"
                v-model="textChatComputed[context.value]"
                :label="context.label"
                class="mr-2"
              >
                <template #help>
                  <rtb-inline-help> {{ context.label }} </rtb-inline-help>
                </template>
              </rtb-checkbox>

              <RtbCheckbox
                v-model="localGame.hideTeamTags"
                label="Hide Team Tag Except Breakouts"
                class="mr-2"
              />

              <RtbCheckbox
                v-model="localGame.removeScribe"
                label="Remove scribe label"
                class="mr-2"
              />

              <RtbCheckbox
                v-model="localGame.videoDisabled"
                label="Disable Video"
                class="mr-2"
              />

              <RtbCheckbox
                v-model="localGame.helpBell"
                label="Help Bell"
                class="mr-2"
              />

              <RtbCheckbox
                v-model="localGame.staticScribeAssignment"
                label="Freeze Scribe"
                class="mr-2"
              >
                <template #help>
                  <rtb-inline-help>
                    This would allow for an initial scribe assignment, but then
                    NEVER advance the scribe throughout the game
                  </rtb-inline-help>
                </template>
              </RtbCheckbox>
            </div>
          </rtb-card-body>
        </rtb-tab>

        <rtb-tab key="styles" title="Styles">
          <rtb-card-body>
            <rtb-row>
              <rtb-col xs="2">
                <TagSelect v-model="themesTag" />
              </rtb-col>
              <rtb-col xs="3">
                <ThemesProvider :tag="themesTag" #default="{ themes }">
                  <ThemeSelect
                    v-model="localGame.themeID"
                    :options="themes"
                    class="flex-grow"
                    @edit="initThemeEdit"
                    @remove="deleteTheme"
                  >
                  </ThemeSelect>
                </ThemesProvider>
              </rtb-col>
            </rtb-row>
            <StylesEditor
              :value="getStyles(localGame)"
              class="mt-3"
              @input="onStylesInput"
            />
            <div class="mt-3">
              <rtb-row>
                <rtb-col xs="3">
                  <rtb-text-input
                    v-model="localGame.customLogoStyle"
                    label="Custom Logo Style"
                    ><template #help>
                      <rtb-inline-help>Placeholder</rtb-inline-help></template
                    ></rtb-text-input
                  >
                </rtb-col>
              </rtb-row>
              <rtb-button
                color="danger"
                :id="`reset-styles-button-${_uid}`"
                class="mt-3"
                @click="resetStylesToDefault"
                >RESET</rtb-button
              >
              <b-tooltip :target="`reset-styles-button-${_uid}`">
                Remove any game styles and enable inheritance
              </b-tooltip>
              <rtb-button
                color="danger"
                :id="`set-default-styles-button-${_uid}`"
                class="mt-3 ml-3"
                @click="setStylesToDefault"
                >OVERRIDE</rtb-button
              >
              <b-tooltip :target="`set-default-styles-button-${_uid}`">
                Set styles to default values and disable inheritance
              </b-tooltip>
            </div>
          </rtb-card-body>
          <ThemeCustomizerModal
            v-if="currentTheme"
            v-model="themeCustomizerModalVisible"
            :theme="currentTheme"
          />
        </rtb-tab>
        <rtb-tab key="expo" title="Expo">
          <rtb-card-body>
            <rtb-row>
              <rtb-col xs="3">
                <rtb-select
                  v-model="localGame.gameType"
                  :options="gameTypes"
                  label="Game Type"
                  ><template #help>
                    <rtb-inline-help
                      >Determines how the game appears and functions in the Game
                      Rooms tab</rtb-inline-help
                    ></template
                  ></rtb-select
                >
              </rtb-col>
              <rtb-col v-if="localGame.gameType == 'YouTube'" xs="4">
                <rtb-text-input
                  v-model="localGame.youTubeUrl"
                  label="The YouTube CODE -- not the Url"
                />
              </rtb-col>
              <rtb-col v-if="localGame.gameType == 'Our Video'" xs="4">
                <rtb-text-input
                  v-model="localGame.ourVideoUrl"
                  label="Video URL"
                />
              </rtb-col>
              <rtb-col v-if="localGame.gameType == 'Twitch'" xs="4">
                <rtb-text-input
                  v-model="localGame.twitchChannel"
                  label="Twitch Channel"
                />
              </rtb-col>
            </rtb-row>
            <rtb-row>
              <rtb-col xs="2">
                <rtb-text-input v-model="localGame.round" label="Round #" />
              </rtb-col>
              <rtb-col
                xs="2"
                v-if="localGame.gameType == 'YouTube'"
                class="mt-3"
              >
                <rtb-checkbox
                  v-model="localGame.youTubeAutoPlay"
                  label="Auto Play"
                />
              </rtb-col>
            </rtb-row>

            <rtb-row
              class="px-2 py-2"
              v-if="
                localGame.gameType == 'YouTube' ||
                localGame.gameType == 'Our Video'
              "
            >
              <rtb-checkbox
                v-model="localGame.watchParty"
                label="Watch party"
                class="mr-2"
              />
              <rtb-checkbox
                v-model="localGame.videoLoop"
                label="Loop video"
                class="mr-2"
              />
            </rtb-row>
          </rtb-card-body>
        </rtb-tab>
        <rtb-tab key="images" title="Images">
          <rtb-card-body>
            <rtb-row>
              <rtb-col xs="3">
                <image-uploader-next
                  v-model="localGame.image"
                  label="Card Image"
                />
              </rtb-col>
              <rtb-col xs="3">
                <image-uploader-next
                  v-model="localGame.customLogo"
                  label="Custom Logo"
                />
              </rtb-col>
              <rtb-col xs="3">
                <image-uploader-next
                  v-model="localGame.customLogoLarge"
                  label="Custom Logo Large"
                />
              </rtb-col>
              <rtb-col xs="3">
                <image-uploader-next
                  v-model="localGame.pickTeamLogo"
                  label="Pick Team Logo"
                />
              </rtb-col>
            </rtb-row>
            <rtb-row>
              <rtb-col xs="3">
                <rtb-select
                  v-model="localGame.gameCardImage"
                  :options="gameCardImages"
                  identity="value"
                  optionText="label"
                  label="Game Card Images"
                  ><template #help>
                    <rtb-inline-help
                      >Where this game will appear, and in what lists, is
                      controlled by this setting
                    </rtb-inline-help></template
                  ></rtb-select
                >
              </rtb-col>
            </rtb-row>
          </rtb-card-body>
        </rtb-tab>
        <rtb-tab key="images" title="Backgrounds">
          <rtb-card-body>
            <rtb-row>
              <rtb-col
                xs="2"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 18px;
                  color: #fff;
                "
              >
                Opacity {{ gameBackgroundOpacity }}
              </rtb-col>
              <rtb-col xs="10">
                <v-slider
                  v-model="gameBackgroundOpacity"
                  :max="100"
                  :step="1"
                />
              </rtb-col>
            </rtb-row>
            <rtb-row>
              <rtb-col xs="4">
                <image-uploader-next
                  v-model="localGame.backgroundImage"
                  label="Game Background"
                />
              </rtb-col>
              <rtb-col xs="4">
                <image-uploader-next
                  v-model="localGame.bgTriggerA"
                  label="Shift A"
                />
              </rtb-col>
              <rtb-col xs="4">
                <image-uploader-next
                  v-model="localGame.bgTriggerS"
                  label="Shift S"
                />
              </rtb-col>
              <rtb-col xs="4">
                <image-uploader-next
                  v-model="localGame.bgTriggerD"
                  label="Shift D"
                />
              </rtb-col>
              <rtb-col xs="4">
                <image-uploader-next
                  v-model="localGame.bgTriggerF"
                  label="Shift F"
                />
              </rtb-col>
            </rtb-row>
          </rtb-card-body>
        </rtb-tab>
        <rtb-tab key="match-emails" title="Emails Match">
          <rtb-card-body>
            <GameSettingsMatchEmails
              :game="localGame"
              :orgID="orgID"
              :data="mailMatchingData"
              :teams="teams"
              @clear="onMatchingClear"
              @add="onMatchingAdd"
            />
          </rtb-card-body>
        </rtb-tab>

        <rtb-tab key="locked" title="Escape Rooms">
          <AutomationPanel v-model="localGame" />
        </rtb-tab>

        <rtb-tab key="HybridTeams" title="Hybrid Teams">
          <rtb-card-body>
            <GameTeams
              :game="gameTeams"
              :playthroughs="playthroughs"
              :scroll="true"
              @updateLocalGame="updateLocalGame"
              v-if="canLoadClientTeam"
            />
          </rtb-card-body>
        </rtb-tab>

        <rtb-tab key="experimental" title="Experimental">
          <ExperimentalPanel v-model="localGame" />
        </rtb-tab>
      </rtb-tabs>

      <rtb-card-actions>
        <v-switch
          v-if="isTemplateGame"
          color="error"
          v-model="localGame.frozen"
          label="Locked"
        />
        <time :datetime="endDate" class="mr-auto">{{ endDate }}</time>
        <rtb-spinner v-show="loading" />
        <RtbButton
          v-if="adding === false"
          color="grey"
          :disabled="exporting"
          @click="exportAsRoom"
          >Export (AS ROOM)</RtbButton
        >
        <RtbButton
          v-if="adding === false"
          color="grey"
          :disabled="exporting"
          @click="exportAsActivityGroup"
          >Export (AS BLOCK)</RtbButton
        >
        <rtb-button
          v-if="!!game"
          :disabled="loading"
          @click="claim"
          color="secondary"
        >
          <template v-if="!!owner">
            <div>
              <img
                v-if="owner.image"
                :src="owner.image"
                :alt="`${owner.firstname} avatar`"
                class="w-8 h-8 rounded-full mr-1 object-cover"
              />
            </div>
            <div style="margin-right: 5px">
              {{ `${owner.firstname} ${owner.lastname}` }}
            </div>
          </template>
          Claim
        </rtb-button>
        <rtb-button color="dark" :disabled="loading" @click="close">
          Cancel
        </rtb-button>
        <rtb-button v-if="copying" color="secondary" @click="update(true)"
          ><svg-icon name="copy-regular" /> Copy</rtb-button
        >
        <rtb-button v-else-if="adding" color="success" @click="update(true)"
          ><svg-icon name="plus-regular" /> Create</rtb-button
        >
        <rtb-button
          v-else
          :disabled="loading || localGame.frozen"
          @click="update()"
        >
          <svg-icon name="save-regular" /> Save
        </rtb-button>
      </rtb-card-actions>
    </rtb-card>
  </validation-observer>
</template>

<script>
import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import { mapGetters, mapState } from "vuex"
import { BTooltip } from "bootstrap-vue"
import { cloneDeep, omit, isEqual, debounce, isObject } from "lodash"
import { format } from "date-fns"
import { GameType } from "@/entities/GameType"
import { DEFAULT_LATERCOMER_YOUTUBE_CODE } from "@/config"
import rules from "@/config/rules"

import RunStatus from "@shared/enums/Game"

import HostsProvider from "@/providers/hosts.provider"

import ThemesProvider from "@/modules/theme/providers/themes.provider"
import StylesEditor from "@/modules/theme/components/StylesEditor.vue"
import ThemeSelect from "@/modules/theme/components/ThemeSelect.vue"
import TagSelect from "@/modules/theme/components/TagSelect.vue"
import { DEFAULT_THEME_STYLES } from "@/modules/theme/constants"
import { MailMatcher } from "@/services/mail.matching"
import { MODULE_NAME as THEME_MODULE_NAME } from "@/modules/theme/store"
import ThemeModuleGetterTypes from "@/modules/theme/store/getter-types"

import {
  RtbButton,
  RtbInputButton,
  RtbRow,
  RtbCol,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbTextInput,
  RtbSelect,
  RtbCheckbox,
  RtbDatepicker,
  RtbTimepicker,
  RtbTabs,
  RtbTab,
  RtbSpinner,
  RtbInlineHelp
} from "@/components/ui"
import ImageUploaderNext from "@/components/ImageUploader.vue"
import { fetchGameTeamsObject } from "@/services/game.service"
import Team from "@shared/Team"

import ThemeCustomizerModal from "@/modules/theme/components/ThemeCustomizerModal.vue"

import GameSettingsMatchEmails from "./GameSettingsMatchEmails"
import ExperimentalPanel from "./GameSettings/ExperimentalPanel"
import AutomationPanel from "./GameSettings/AutomationPanel"

const MILISECONDS_IN_MINUTE = 60000

import { db } from "@/firebase"

import GameTeams from "@/components/Conversation/GameTeams.vue"
import ChatService from "@/components/Chat/index.js"

import EntryPage from "@/enums/EntryPage"
import RoomType from "@shared/enums/Game"

const AVAILABLE_CHAT_CONTEXT = ChatService.allChatContext()

export default Vue.extend({
  name: "GameSettings",
  components: {
    HostsProvider,
    ValidationObserver,
    BTooltip,
    ThemesProvider,
    GameSettingsMatchEmails,
    StylesEditor,
    ExperimentalPanel,
    AutomationPanel,
    ThemeSelect,
    TagSelect,
    RtbButton,
    RtbInputButton,
    RtbRow,
    RtbCol,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbTextInput,
    RtbSelect,
    RtbCheckbox,
    RtbDatepicker,
    RtbTimepicker,
    RtbTabs,
    RtbTab,
    RtbSpinner,
    RtbInlineHelp,
    ImageUploaderNext,
    ThemeCustomizerModal,
    GameTeams
  },
  props: {
    game: {
      type: Object
    },
    copying: {
      type: Boolean,
      required: false
    },
    adding: {
      type: Boolean,
      required: false
    }
  },
  beforeDestroy() {
    this.playthroughRef &&
      this.playthroughRef.off("value", this.fetchPlayThroughs)
  },
  async created() {
    this.mailMatcher = new MailMatcher(this.localGame?.clientID)
    const gameID = this.game?.theKey || this.game?.id
    if (gameID) {
      this.mailMatchingData = await this.mailMatcher.getMatchByGameID(gameID)
      await this.fetchTeams(gameID)
    }
  },
  data() {
    return {
      entryPageOptions: Object.values(EntryPage),
      copyToOrgID: this.$store.getters.orgID,
      owner: null,
      tab: 0,
      listTabs: Object.values(RunStatus).map(key => ({
        label: key,
        value: key
      })),
      accessOptions: [
        { label: "Public", value: null },
        { label: "Private", value: "restricted" }
      ],
      gameCardImages: [
        { label: "None", value: "" },
        { label: "Celebration", value: "celebration.png" },
        { label: "Game Show", value: "gameshow.png" },
        { label: "Town Hall", value: "townhall.png" },
        { label: "Karaoke", value: "karaoke.png" },
        { label: "Discussion Group", value: "discussion.png" },
        { label: "Green Room", value: "green.png" },
        { label: "All Hands", value: "allhands.png" },
        { label: "Theater", value: "theater.png" },
        { label: "Movie", value: "movie.png" },
        { label: "Q & A", value: "qanda.png" },
        { label: "Team Wrok", value: "teamwork.png" },
        { label: "Trophy", value: "trophy.png" }
      ],
      viewing: false,
      loading: false,
      themeCustomizerModalVisible: false,
      localGame: null,
      mailMatchingData: [],
      mailMatcher: null,
      teams: null,
      themesTag: undefined,
      twilioChannelOptions: [0, 1, 2, 3, 4, 5],
      playthroughs: [],
      canLoadClientTeam: false,
      textChat: [
        { value: AVAILABLE_CHAT_CONTEXT.TEAM, label: "Team Chat" },
        { value: AVAILABLE_CHAT_CONTEXT.GAME, label: "Room Chat" },
        { value: AVAILABLE_CHAT_CONTEXT.LOBBY, label: "Lobby Chat" }
      ],
      exporting: false
    }
  },
  computed: {
    ...mapState(["showProToolbarForModerators"]),
    ...mapGetters("auth", ["user", "isHost", "token", "hasPreGame", "client"]),
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters(["onlineUsersArray"]),
    ...mapGetters("soundeffect", ["getAllAudios"]),
    ...mapGetters({ localGameteams: "chats" }),
    localShowProModeratorToolbar: {
      get() {
        return this.showProToolbarForModerators
      },
      set(val) {
        this.$store.commit("SET_SHOW_PRO_TOOLBAR_FOR_MODERATORS", val)
      }
    },
    gameBackgroundOpacity: {
      get() {
        const int = parseInt(this.localGame?.backgroundOpacity)
        return isNaN(int) ? 30 : int
      },
      set(value) {
        this.localGame = { ...this.localGame, backgroundOpacity: value }
      }
    },
    entryPageComputed: {
      get() {
        return this.localGame?.entryPage || EntryPage.PICKTEAMS
      },
      set(value) {
        this.localGame = {
          ...this.localGame,
          entryPage: value || null
        }
      }
    },
    accessComputed: {
      get() {
        return this.localGame?.access ? this.localGame.access : null
      },
      set(value) {
        this.localGame = {
          ...this.localGame,
          access: value === "Private" ? "restricted" : null
        }
      }
    },
    ownerID() {
      return this.localGame?.ownerID
    },
    endDate() {
      return this.localGame?.endTimestamp
        ? format(new Date(this.localGame.endTimestamp), "MM.dd.yyyy HH:MM z")
        : undefined
    },
    themes() {
      return this.$store.getters[
        `${THEME_MODULE_NAME}/${ThemeModuleGetterTypes.NORMALIZED_THEMES}`
      ]
    },
    currentTheme() {
      return this.themes.find(theme => theme.id === this.localGame.themeID)
    },
    gameTypes() {
      return this.$store.getters["Games/gameTypes"]
    },
    user() {
      return this.$store.getters.user
    },
    orgID() {
      return this.$store.getters.orgID
    },
    orgs() {
      var arr = Object.entries(this.$store.getters.orgs)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i][0]
        obj.label = arr[i][1].name
        newArr.push(obj)
      }
      newArr.push({ id: 0, label: "All Organizations" })
      return newArr
    },
    normalizedGameDuration: {
      get() {
        const duration = this.localGame.duration
        return duration && typeof duration === "number"
          ? this.localGame.duration / MILISECONDS_IN_MINUTE
          : null
      },
      /**
       * @param {number} value
       */
      set: debounce(function (value) {
        this.$set(this.localGame, "duration", value * MILISECONDS_IN_MINUTE)
      }, 100)
    },
    gameAudio() {
      const gameAudio = Object.values(this.getAllAudios || {}).map(
        ({ source, name }) => ({
          id: source,
          label: name
        })
      )
      gameAudio.unshift({ id: 0, label: "Nothing" })

      return gameAudio
    },
    gameTeams() {
      return {
        ...this.localGame,
        teams: Team.normalize(this.localGameteams || {})
      }
    },
    isTemplateGame() {
      return this.localGame.runStatus === RunStatus.TEMPLATE
    },
    textChatOptions() {
      return this.hasPreGame
        ? this.textChat
        : this.textChat.filter(
            context => context.value !== AVAILABLE_CHAT_CONTEXT.LOBBY
          )
    },
    textChatComputed() {
      const _this = this

      const obj = {}

      Object.defineProperty(obj, AVAILABLE_CHAT_CONTEXT.TEAM, {
        get() {
          return _this.$store.getters.isTeamChatOn(_this.game)
        },
        set(val) {
          _this.localGame[AVAILABLE_CHAT_CONTEXT.TEAM] = val
        }
      })

      Object.defineProperty(obj, AVAILABLE_CHAT_CONTEXT.GAME, {
        get() {
          return _this.$store.getters.isRoomChatOn(_this.game)
        },
        set(val) {
          _this.localGame[AVAILABLE_CHAT_CONTEXT.GAME] = val
        }
      })

      Object.defineProperty(obj, AVAILABLE_CHAT_CONTEXT.LOBBY, {
        get() {
          return _this.$store.getters.isLobbyChatOn(_this.game)
        },
        set(val) {
          _this.localGame[AVAILABLE_CHAT_CONTEXT.LOBBY] = val
        }
      })

      return obj
    },
    playthroughRef() {
      if (!this.canLoadClientTeam) return null
      return db
        .auxiliary()
        .ref(`client/${this.localGame?.clientID}/playthrough`)
    }
  },

  watch: {
    ownerID: {
      async handler(value) {
        if (!value) {
          this.owner = null
          return
        }
        const snapshot = await db.ref(`org/1/users/${value}`).once("value")
        const user = snapshot.val()
        this.owner = user
      },
      immediate: true
    },
    game: {
      handler(value) {
        this.localGame = cloneDeep(value)
      },
      immediate: true
    },
    adding: {
      handler(value) {
        if (value) {
          // TODO(Andrew): introduce GameFactory
          this.localGame = {
            name: "New Game",
            runStatus: RunStatus.USER_TEMPLATE,
            startTimestamp: Date.now(),
            hostUserID: this.user.id,
            players: 30,
            potato: 15,
            locked: true,
            latecomerVideoCode: DEFAULT_LATERCOMER_YOUTUBE_CODE
          }
        }
      },
      immediate: true
    },
    [`localGame.themeID`]() {
      this.$store.dispatch(`setLocalGame`, this.localGame)
    },
    [`localGame.styles`]() {
      this.$store.dispatch(`setLocalGame`, this.localGame)
    },
    [`localGame.frozen`](value) {
      if (value) {
        this.update()
      }
    },
    playthroughRef: {
      handler(value, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.fetchPlayThroughs)
        }
        if (value) {
          value.on("value", this.fetchPlayThroughs)
        }
      },
      immediate: true
    }
  },

  methods: {
    updateLocalGame(updatePayload) {
      if (!isObject(updatePayload)) return
      for (let prop in updatePayload) {
        if (updatePayload[prop] === undefined) {
          updatePayload[prop] = null
        }
        this.$set(this.localGame, prop, updatePayload[prop])
      }
    },
    claim() {
      this.localGame = { ...this.localGame, ownerID: this.user?.id || null }
    },
    close() {
      if (!isEqual(this.localGame, this.game)) {
        this.$store.dispatch(`setLocalGame`, this.game)
      }
      this.$emit("close")
    },
    remove() {
      this.$store.dispatch("Games/removeGame", this.game)
      this.deleting = false
    },
    // TODO(Andrew|someone): refactor this method
    update(creating = false) {
      this.loading = true
      if (creating) {
        this.localGame.ownerID = this.user?.id || null
      }

      if (!this.localGame.gameType) {
        this.localGame.gameType = GameType.Standard
      }

      this.$refs.observer.validate().then(async valid => {
        if (!this.localGame?.theKey) this.localGame.theKey = this.localGame.id
        if (this.adding) {
          await this.$store.dispatch("Games/addGame", this.localGame)
          this.$emit("create")
        } else if (this.copying) {
          const copiedFrom = await this.$store.dispatch("Games/copyGame", {
            game: this.localGame,
            orgID: this.copyToOrgID
          })
          const copiedGame = await this.$services
            .get("game")
            .then(service => service.getGameByID(this.copyToOrgID, copiedFrom))
          this.$emit("copy", copiedGame, this.copyToOrgID)
        } else {
          const updatedGame = await this.$store.dispatch(
            "Games/updateGame",
            this.localGame
          )
          this.$emit("update", updatedGame)
        }

        this.mailMatcher.setGameMailMatches(
          this.localGame?.theKey,
          this.mailMatchingData
        )

        this.$emit("close")

        this.loading = false

        console.log("🎮 Game has been updated", this.localGame)
      })
    },
    getStyles(game) {
      return { ...DEFAULT_THEME_STYLES, ...game.styles }
    },
    /** @param {import('@/modules/theme/components/types').StylesEditorInputEventPayload} payload */
    onStylesInput(styles) {
      this.$set(this.localGame, "themeID", null)
      this.$set(this.localGame, "styles", styles)
    },
    setStylesToDefault() {
      const game = this.localGame
      this.localGame = {
        ...game,
        styles: cloneDeep(DEFAULT_THEME_STYLES),
        themeID: null
      }
    },
    resetStylesToDefault() {
      const game = this.localGame
      this.localGame = { ...game, styles: null, themeID: null }
    },
    deleteTheme() {
      this.$set(this.localGame, "themeID", null)
    },
    getRules() {
      return rules
    },
    getClientIDRule() {
      return this.adding
        ? omit(rules.GameClientID, "required")
        : rules.GameClientID
    },
    initThemeEdit() {
      this.themeCustomizerModalVisible = true
    },
    async fetchTeams(gameID) {
      const teams = await fetchGameTeamsObject({
        orgID: this.orgID,
        gameID
      })
      const teamsList = {}
      if (teams) {
        teamsList[gameID] = Object.entries(teams)
          .filter(([id, item]) => id && item.show)
          .map(([id, item]) => ({ ...item, id }))
        this.teams = teamsList
      }
    },
    onMatchingClear() {
      this.mailMatchingData = []
    },
    onMatchingAdd(data) {
      console.log(data)
      this.mailMatchingData = this.mailMatchingData.concat(data)
    },
    async fetchPlayThroughs(snap) {
      this.playthroughs = Object.entries(snap.val() || {}).map(
        ([id, playthrough]) => ({ ...playthrough, id, label: id })
      )
    },
    onTabChange(key) {
      if (key === "HybridTeams") {
        this.canLoadClientTeam = true
      }
    },
    async exportAsActivityGroup() {
      this.exporting = true
      try {
        const service = await this.$services.get("export")
        const gs = await this.$services.get("game")
        const activities = await gs.listMissions(this.orgID, this.localGame.id)
        const id = await service.exportGame({
          ...this.localGame,
          runStatus: RoomType.BLOCK,
          activities
        })
        if (id !== this.localGame.contentToolID) {
          this.localGame.contentToolID = id
          await this.update()
        }
        this.$info(`Success`)
      } catch (e) {
        this.$info(e.message)
      } finally {
        this.exporting = false
      }
    },
    async exportAsRoom() {
      this.exporting = true
      try {
        const service = await this.$services.get("export")
        const gs = await this.$services.get("game")
        const activities = await gs.listMissions(this.orgID, this.localGame.id)
        const id = await service.exportGame({
          ...this.localGame,
          runStatus: RoomType.MASTER,
          activities
        })
        if (id !== this.localGame.contentToolID) {
          this.localGame.contentToolID = id
          await this.update()
        }
        this.$info(`Success`)
      } catch (e) {
        this.$info(e.message)
      } finally {
        this.exporting = false
      }
    }
  }
})
</script>

<style lang="scss">
.game-settings {
  // TODO(Andrew|soemone): tabs min height
  .rtb-card__body {
    min-height: 378px;
  }
  .checkbox-group {
    display: inline-block;
    &--active {
      border: 1px solid $primary_accent_color;
      padding-left: 7px;
      padding-top: 2px;
      padding-bottom: 2px;
      border-radius: 5px;
      margin-right: 5px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
}
</style>
